<template>
  <div>
    <b-card title="Welcome to the Brime Developer Center 🚀">
      <b-card-text>Here you will find everything you need to integrate into the Brime platform. We can't wait to see what you create.</b-card-text>
    </b-card>

    <b-card title="Getting Started">
      <b-card-text>First, create an app from the menu to the left.</b-card-text>
      <b-card-text>Use the client-id and secret to generate a JWT token for the user.</b-card-text>
    </b-card>

    <b-card title="YouTube Videos">
      <b-card-text>We will soon release a developer series on YouTube to help you get started.</b-card-text>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
  },
}
</script>

<style>

</style>
